import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"   viewBox="0 0 872.000000 887.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,887.000000) scale(0.100000,-0.100000)">



<path d="M4060 7969 c-457 -33 -985 -186 -1398 -405 -171 -90 -419 -248 -507
-323 -17 -14 -51 -42 -75 -61 -196 -156 -396 -355 -556 -555 -124 -155 -176
-229 -303 -435 -41 -67 -161 -306 -209 -415 -133 -309 -232 -693 -273 -1065
-16 -148 -16 -562 0 -710 77 -699 307 -1284 725 -1845 33 -44 62 -82 65 -85 3
-3 45 -50 92 -105 337 -390 786 -719 1269 -931 69 -30 134 -59 145 -64 35 -17
287 -97 385 -123 318 -84 494 -107 863 -114 470 -9 780 37 1218 181 540 177
1045 494 1444 907 393 407 661 844 843 1374 184 535 235 1140 146 1720 -94
614 -365 1226 -759 1714 -80 99 -269 301 -351 376 -54 49 -101 92 -104 95 -3
3 -25 21 -50 40 -25 19 -50 39 -56 45 -109 100 -448 318 -655 420 -124 62
-386 168 -515 208 -228 72 -559 136 -809 157 -136 11 -419 11 -575 -1z m386
-428 c46 -79 84 -145 84 -147 0 -2 25 -44 55 -94 30 -50 55 -92 55 -94 0 -3
44 -80 98 -173 55 -92 151 -258 214 -368 163 -283 212 -367 325 -560 54 -93
102 -177 106 -185 4 -8 17 -31 28 -50 19 -31 75 -128 319 -550 37 -63 145
-250 240 -415 192 -333 211 -367 295 -510 33 -55 66 -113 75 -130 9 -16 41
-73 71 -125 59 -99 163 -279 242 -416 26 -44 47 -88 47 -97 0 -15 -25 -17
-272 -17 l-273 0 -60 92 c-145 222 -379 482 -587 648 -532 428 -1161 650
-1843 650 -273 0 -681 -68 -902 -150 -35 -13 -53 -16 -53 -8 0 9 156 286 221
393 11 17 22 37 26 45 3 8 25 47 48 85 23 39 79 135 125 215 46 80 115 199
153 265 38 66 87 149 107 185 20 36 79 137 130 225 207 356 385 665 418 725
14 25 28 50 32 55 4 6 69 117 144 248 196 341 195 339 213 375 9 18 21 31 26
29 5 -1 47 -67 93 -146z m574 39 c124 -42 334 -145 446 -218 450 -296 753
-727 878 -1246 66 -276 68 -651 4 -911 -22 -91 -55 -202 -64 -217 -8 -13 -8
-13 -208 335 l-176 308 0 78 c0 170 -59 411 -148 603 -120 260 -337 500 -594
659 -47 29 -61 46 -113 138 -33 57 -115 199 -182 315 -68 116 -123 216 -123
222 0 14 145 -20 280 -66z m-1050 62 c0 -5 -29 -57 -65 -117 -36 -59 -65 -109
-65 -111 0 -2 -22 -41 -49 -86 -27 -46 -80 -137 -119 -203 -68 -118 -72 -122
-152 -174 -213 -139 -357 -289 -495 -517 -130 -214 -215 -506 -215 -739 0 -67
11 -45 -203 -415 -123 -212 -144 -248 -159 -277 -6 -13 -14 -23 -18 -23 -7 0
-51 142 -74 240 -28 120 -40 244 -40 420 -1 243 24 408 99 655 34 109 144 343
210 445 205 317 516 592 838 745 210 99 507 191 507 157z m55 -2966 c319 -43
640 -148 917 -301 44 -25 84 -45 88 -45 5 0 10 -3 12 -8 2 -4 34 -27 73 -52
181 -117 392 -305 539 -480 88 -104 136 -166 136 -174 0 -3 -183 -6 -407 -6
l-408 0 -50 41 c-79 64 -253 177 -339 221 -192 97 -437 174 -643 203 -135 19
-387 19 -520 0 -350 -50 -692 -194 -956 -404 l-77 -61 -195 0 c-125 0 -195 4
-195 10 0 9 20 46 70 130 8 14 65 113 153 265 22 39 52 90 67 115 15 25 53 91
85 148 l58 103 106 53 c260 130 593 226 891 255 126 13 457 5 595 -13z m-567
-1608 l3 -148 174 0 175 0 0 150 0 150 80 0 80 0 0 -400 0 -400 -80 0 -80 0 0
180 0 180 -175 0 -175 0 0 -180 0 -180 -82 0 -83 0 2 397 c2 219 3 399 3 401
0 2 35 2 78 0 l77 -3 3 -147z m1147 126 c176 -43 269 -201 251 -427 -14 -178
-102 -293 -253 -332 -41 -11 -111 -15 -245 -15 l-188 0 0 393 c0 217 2 396 4
398 11 11 376 -3 431 -17z m1608 17 l117 -1 0 -65 0 -65 -115 0 -115 0 0 -330
0 -330 -80 0 -80 0 0 330 0 330 -115 0 -115 0 0 63 c0 35 3 66 6 70 3 3 90 4
193 3 102 -2 239 -4 304 -5z m-4060 -48 c13 -42 105 -308 220 -640 20 -55 33
-104 30 -107 -4 -3 -41 -6 -83 -6 -88 0 -75 -13 -121 123 l-23 67 -147 0 -148
0 -30 -92 -29 -93 -81 -3 c-55 -2 -81 1 -81 8 0 7 27 91 61 188 33 97 93 273
132 389 l72 212 107 1 106 0 15 -47z m839 24 c20 -11 50 -35 65 -55 24 -32 28
-46 28 -103 0 -71 -24 -122 -73 -151 l-24 -15 49 -25 c79 -42 121 -124 107
-216 -12 -84 -50 -139 -124 -178 -31 -17 -64 -19 -267 -22 l-233 -3 0 396 0
396 218 -3 c186 -3 222 -6 254 -21z m2458 -99 c24 -68 67 -192 96 -276 30 -83
54 -155 54 -160 0 -4 18 -56 40 -115 22 -60 40 -113 40 -118 0 -5 -38 -9 -84
-9 l-84 0 -30 93 -29 92 -150 3 -150 3 -29 -93 -30 -93 -82 -3 c-45 -2 -82 1
-82 6 0 5 18 62 41 128 23 65 81 234 129 374 48 140 91 263 95 273 6 15 21 17
110 17 l103 0 42 -122z m1526 -268 c73 -212 133 -391 134 -398 0 -10 -20 -12
-82 -10 l-83 3 -31 90 -31 90 -149 0 -149 0 -29 -92 -30 -93 -84 0 c-96 0 -92
-17 -37 140 19 52 77 222 131 378 l97 283 105 -3 105 -3 133 -385z"/>
<path d="M4334 6448 c-9 -18 -48 -87 -86 -153 -39 -66 -98 -167 -131 -225
-132 -229 -172 -298 -219 -376 -26 -45 -48 -85 -48 -88 0 -3 26 -6 58 -6 84 0
360 -37 492 -66 219 -49 357 -89 533 -157 37 -14 67 -23 67 -20 0 4 -36 68
-79 143 -43 74 -137 236 -209 360 -210 366 -354 615 -358 618 -1 2 -10 -11
-20 -30z"/>
<path d="M4330 2815 l0 -268 103 7 c112 8 172 29 207 73 73 93 63 323 -17 395
-36 33 -97 50 -195 56 l-98 5 0 -268z"/>
<path d="M1977 2896 c-26 -80 -47 -149 -47 -155 0 -6 39 -9 98 -7 78 1 97 4
96 16 -2 26 -87 290 -94 290 -3 0 -27 -65 -53 -144z"/>
<path d="M2680 2995 l0 -95 99 0 c90 0 100 2 125 25 37 34 37 101 0 139 -25
25 -32 26 -125 26 l-99 0 0 -95z"/>
<path d="M2680 2666 l0 -116 110 0 c123 0 164 13 180 56 13 34 13 87 -1 117
-18 39 -55 50 -176 55 l-113 4 0 -116z"/>
<path d="M5251 2903 c-24 -76 -46 -145 -48 -153 -4 -14 10 -16 91 -15 52 0 97
3 101 7 7 7 -85 298 -95 298 -3 0 -25 -62 -49 -137z"/>
<path d="M6726 3029 c-19 -53 -93 -284 -92 -286 4 -5 189 -12 196 -8 8 5 -82
290 -95 303 -2 3 -7 -1 -9 -9z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
